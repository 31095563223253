<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <img
          src="https://ik.imagekit.io/aryraditya/ceremonyku/corner_2x_sZXnEWtgp.png?tr=w-500"
          style="position: absolute; top: 0; right: 0; width: 75vw; max-width: 400px; transform: translateY(-20%) translateX(20%); opacity: .5"
        />
        <div class="text-center w-100">
          <div data-aos="fade-down" class="pic">
            <img :src="coverImage" class="" />
          </div>
          <div class="title mb-4">Undangan Pawiwahan</div>
          <!-- <span>from</span> -->
          <div class="mb-4 name" data-aos="fade-down" data-aos-delay="200">
            {{ wedding.man.surname }}
            <span style="margin-top: -1rem; margin-bottom: -1rem;">&</span>
            {{ wedding.woman.surname }}
          </div>

          <div
            class="guest-box mb-3 d-block mx-auto"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <div class="guest-box__title">
              Kepada {{ $root.guest.title || "Bpk/Ibu/Sdr/i" }}.
            </div>
            <div v-if="$root.guest.name" class="guest-box__name">
              <span>{{ $root.guest.name }}</span>
            </div>

            <div class="guest-box__note">
              Tanpa mengurangi rasa hormat, kami mengundang anda untuk hadir
              pada upacara Pawiwahan putra-putri kami.
            </div>
          </div>

          <button
            data-aos-delay="800"
            data-aos="fade-up"
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="bg-white agw-content"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro
        id="intro"
        style="position:relative; box-shadow: 0 5px 15px rgb(175 150 105 / 34%)"
      />

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
      <!-- <AGWMap
        :height="450"
        :url="map"
        :directionUrl="direction"
        :location="location"
        id="map"
      /> -->
    </div>

    <div class="agw-content" style="background: transparent">
      <!-- <Himbauan /> -->
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <div class="container">
      <p
        class="font-secondary text-center"
        data-aos="fade-up"
        data-aos-delay="800"
        style="font-size: 2rem; opacity: .7"
      >
        Om Santhi Santhi Santhi Om
      </p>
    </div>

    <FooterCeremonyku ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/crk/pardi-anik/LFS07739.JPG?updatedAt=1702603943616";
const ImageHero2 =
  "https://ik.imagekit.io/crk/pardi-anik/LFS07938.JPG?updatedAt=1702603946776";
const ImageHero3 =
  "https://ik.imagekit.io/crk/pardi-anik/LFS07630.JPG?updatedAt=1702603943903";

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
// import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";
// import Himbauan from "./components/Himbauan.vue";
import FooterCeremonyku from "@/components/Footer/Ceremonyku";

const SESSION = [["2023-12-26 10:00", "Selesai"]];

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    // AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    // Himbauan,
    FooterCeremonyku
  },
  data() {
    return {
      title: "Wedding",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location: "Br. Sulangai, Desa Sulangai, Kec. Petang, Kab. Badung",
      coverImage:
        "https://ik.imagekit.io/crk/pardi-anik/LFS07630.JPG?updatedAt=1702623253067&tr=w-400,h-400,fo-custom,cm-extract",
      map:
        "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3947.6820972101377!2d115.20226887501082!3d-8.334351791701794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMjAnMDMuNyJTIDExNcKwMTInMTcuNCJF!5e0!3m2!1sid!2sid!4v1702604118928!5m2!1sid!2sid",
      direction: "https://maps.app.goo.gl/RMkrdqZhcxtth3zn9",
      time: {
        start: this.$moment("2023-12-26 10:00"),
        end: "Selesai"
      },
      wedding: {
        man: {
          name: "I Kadek Parmadi S.T",
          surname: "Pardi",
          parents: "I Wayan Yatna S.pd.M.M & Ni Putu Murniati",
          description: "Putra kedua",
          address: "Br. Sulangai, Desa Sulangai, Kec. Petang, Kab. Badung",
          photo:
            "https://ik.imagekit.io/crk/pardi-anik/FOTO%20SENDIRI%20COW.JPG?updatedAt=1702604200688&tr=w-540,h-676,fo-custom,cm-extract"
        },
        woman: {
          name: "Ni Luh Anik Setiadewi S.S",
          surname: "Anik",
          parents: "I Made Budiarta & Ni Wayan Kompiang Widani",
          address: "Br. Wanekeling, Ds. Sulangai, Kec. Petang, Kab. Badung",
          description: "Putri pertama",
          photo:
            "https://ik.imagekit.io/crk/pardi-anik/FOTO%20SENDIRI%20CEW.JPG?updatedAt=1702605375387&tr=w-644,h-804,fo-custom,cm-extract"
        }
      },
      mepandes: [
        // {
        //   name: "I Made Wahyudika Adi Putra"
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // },
        // {
        //   name: "I Nyoman Adi Ariawan"
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // },
        // {
        //   name: "Ni Kadek Nadia Putri Arimbawa"
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // },
        // {
        //   name: "I Putu Arya Megadhana"
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // }
      ],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
    session() {
      const url = new URLSearchParams(window.location.search);
      const sesi = Number(url.get("sesi")) || 1;
      return sesi > SESSION.length ? 1 : sesi;
    }
  },
  mounted() {
    const [start, end] = SESSION[this.session - 1] || SESSION[0];

    this.time.start = this.$moment(start);
    this.time.end = end;

    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>

<style lang="scss" scoped>
.overflow-fade-enter-active {
  transition: all 1s ease;
}
.overflow-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.overflow-fade-enter,
.overflow-fade-leave-to {
  opacity: 0;
}
</style>
